import React, { useState } from "react";
import c from "./Service.module.scss";
import { Link, useNavigate } from "react-router-dom";
import image1 from "../../assets/service1.jpg";
import image2 from "../../assets/service2.jpg";
import image3 from "../../assets/service3.jpg";
import image4 from "../../assets/service4.jpg";
import Marquee from "react-fast-marquee";
import { FaArrowRightLong } from "react-icons/fa6";
import Popup from "../popUp/PopUp"; // Ensure correct import path

const data = [
  {
    title: "Диагностические процедуры",
    image: image1,
    link: "/service",
  },
  {
    title: "Косметологические и эстетические процедуры",
    image: image2,
    link: "/service",
  },
  {
    title: "Лечение заболеваний",
    image: image3,
    link: "/service",
  },
  {
    title: "Консультации гинеколога",
    image: image4,
    link: "/service",
  },
];

const Services = ({ isMain = true }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = (link) => {
    if (!isMain) {
      window.scrollTo(0, 0);
    }
    navigate(link);
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <div className={c.marquee_container}>
        <Marquee gradient={false} speed={40} className={c.marquee}>
          <h4>OUR SERVICES</h4>
          <h4>OUR SERVICES</h4>
          <h4>OUR SERVICES</h4>
          <h4>OUR SERVICES</h4>
          <h4>OUR SERVICES</h4>
          <h4>OUR SERVICES</h4>
          <h4>OUR SERVICES</h4>
          <h4>OUR SERVICES</h4>
          <h4>OUR SERVICES</h4>
          <h4>OUR SERVICES</h4>
          <h4>OUR SERVICES</h4>
          <h4>OUR SERVICES</h4>
          <h4>OUR SERVICES</h4>
          <h4>OUR SERVICES</h4>
          <h4>OUR SERVICES</h4>
          <h4>OUR SERVICES</h4>
        </Marquee>
        <h3>НАШИ УСЛУГИ</h3>
      </div>
      <div className={c.cards}>
        {data.map((item) => (
          <div className={c.card} key={item.title}>
            <div
              className={c.info}
              style={{ backgroundImage: `url(${item.image})` }}
            >
              <h3>{item.title.toUpperCase()}</h3>
              <Link to={item.link} onClick={() => handleClick(item.link)}>
                подробнее <FaArrowRightLong />
              </Link>
            </div>
            <button onClick={openPopup}>записаться</button>
          </div>
        ))}
      </div>
      {isPopupOpen && <Popup onClose={closePopup} />}
    </>
  );
};

export default Services;
