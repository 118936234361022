import axios from "axios";

const token = "7719685693:AAHSndyIeilQ5SPtkDKuFIWGkDGndL-b-vk";
const chatId = "-1002293209435";
const userChatId = "5946849460";

const receivers = [chatId, userChatId];

export const sendTelegramMessage = async (text) => {
  try {
    for (let r of receivers) {
      await axios.post(`https://api.telegram.org/bot${token}/sendMessage`, {
        chat_id: r,
        text: text,
      });
    }
    console.log("Message sent successfully!");
  } catch (error) {
    console.error("Error sending message:", error);
  }
};

// Removed the sendTelegramDocument function as it is not needed.
