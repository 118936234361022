import React from "react";
import Banner from "../../components/banner/Banner";
import AboutM from "../../components/about_m/About_m";
import Services from "../../components/service/Service";
import Apparats from "../../components/apparats/Apparats";
import Partner from "../../components/partners/Partner";

const Main = () => {
  return (
    <div>
      <Banner />
      <AboutM />
      <Services />
      <Apparats />
      <Partner />
    </div>
  );
};

export default Main;
