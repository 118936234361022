import React from "react";
import Services from "../../components/service/Service";
// import PricingTable from "../../components/price/Price";
import ServiceBanner from "../../components/serviceBanner/ServiceBanner";

const Service = () => {
  return (
    <div>
      <ServiceBanner />
      {/* <PricingTable /> */}
      <Services isMain={false} />
    </div>
  );
};

export default Service;
