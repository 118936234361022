import React from "react";
import c from "./About.module.scss";
import about from "../../assets/about.png";

const AboutComponent = () => {
  return (
    <div>
      <img src={about} alt="about" className={c.about_image} />
      <div className={c.about_wrapper}>
        <div className={c.about_context}>
          <p>
            Наша <m style={{ color: "#9747FF" }}> гинекологическая </m> клиника
            — это современный центр заботы о женском здоровье. Наша команда
            опытных врачей использует передовые методики и индивидуальный подход
            к каждой пациентке, обеспечивая комфорт и безопасность на каждом
            этапе. Мы стремимся создать доверительную и уютную атмосферу, чтобы
            визиты к гинекологу были максимально комфортными.
          </p>
          <div className={c.about_numbers}>
            <div className={c.about_numbers_item}>
              <h4>+10</h4>
              <p>лет опыта</p>
            </div>
            <div className={c.about_numbers_item}>
              <h4>+2000</h4>
              <p>Довольных клиентов</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutComponent;
