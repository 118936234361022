import React, { useState } from "react";
import c from "./PopUp.module.scss";
import image from "../../assets/serviceBanner.png";
import { sendContact } from "../../apis/contactSender";

const Popup = ({ onClose }) => {
  const handleOutsideClick = (e) => {
    if (e.target.className === c.popup_overlay) {
      onClose();
    }
  };

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    if (!name || !phone) {
      return window.alert("Please fill the form fields first!");
    }
    const data = { name, phone };
    sendContact(data);
    setName("");
    setPhone("");
    onClose();
    return;
  };

  return (
    <div className={c.popup_overlay} onClick={handleOutsideClick}>
      <div className={c.popup_content}>
        <button className={c.close_btn} onClick={onClose}>
          &times;
        </button>
        <h2>Запись на прием</h2>
        <form className={c.popup_form} onSubmit={onSubmit}>
          <label>
            <b>Специалист: </b> Якубова Юлдуз
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Ваше имя"
            required
          />
          <input
            type="text"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Ваш номер"
            required
          />
          <button type="submit">Записаться</button>
        </form>
        <img src={image} alt="service" />
      </div>
    </div>
  );
};

export default Popup;
