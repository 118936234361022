import React from "react";
import logo from "../../assets/logo.png"; // Adjust the path as needed
import c from "./Header.module.scss"; // Import your CSS Module
import { FaBars, FaPhoneAlt } from "react-icons/fa"; // Icons for mobile menu and phone
import { Link } from "react-router-dom"; // React Router for navigation

const Header = () => {
  // Function to open/close the mobile menu
  function openMenu() {
    const menu = document.querySelector("#mobile_menu");

    if (!menu.classList.contains(c.active_menu)) {
      menu.classList.add(c.active_menu); // Add active class
    } else {
      menu.classList.remove(c.active_menu); // Remove active class
    }

    // Manage body overflow based on menu state
    if (menu.classList.contains(c.active_menu)) {
      document.body.style.overflow = "hidden"; // Prevent scrolling
    } else {
      document.body.style.overflow = ""; // Restore scrolling
    }
  }

  // Function to close the mobile menu
  function closeMenu() {
    const menu = document.querySelector("#mobile_menu");
    menu.classList.remove(c.active_menu); // Remove active class
    document.body.style.overflow = ""; // Restore scrolling
  }

  return (
    <header>
      <Link to={"/"} className={c.logo}>
        <img src={logo} alt="logo" />
      </Link>
      <nav>
        <ul>
          <li>
            <Link to={"/service"}>Наши Услуги</Link>
          </li>
          <li>
            <Link to={"/about"}>О нас</Link>
          </li>
          <li>
            <Link to={"/contact"}>Контакты</Link>
          </li>
        </ul>
      </nav>
      <a className={c.contact} href="tel:+998908985578">
        <FaPhoneAlt /> <p>+998 90 898 55 78</p>
      </a>

      {/* Mobile menu toggle button */}
      <FaBars className={c.menu_bar} onClick={openMenu} />

      {/* Mobile menu */}
      <div className={c.mobile_menu} id="mobile_menu">
        <ul>
          <li onClick={closeMenu}>
            <Link to={"/service"}>Наши Услуги</Link>
          </li>
          <li onClick={closeMenu}>
            <Link to={"/about"}>О нас</Link>
          </li>
          <li onClick={closeMenu}>
            <Link to={"/contact"}>Контакты</Link>
          </li>
        </ul>
        <a href="tel:+998908985578" onClick={closeMenu}>
          <FaPhoneAlt /> <p>+998 90 898 55 78</p>
        </a>
      </div>
    </header>
  );
};

export default Header;
