import React from "react";
import c from "./About_m.module.scss";
import Marquee from "react-fast-marquee";
import { FaArrowRightLong } from "react-icons/fa6";
import pic from "../../assets/about_kassa.jpg";

const AboutM = () => {
  return (
    <>
      <Marquee gradient={false} speed={40} className={c.marquee}>
        <h4>ABOUT US</h4>
        <h4>ABOUT US</h4>
        <h4>ABOUT US</h4>
        <h4>ABOUT US</h4>
        <h4>ABOUT US</h4>
        <h4>ABOUT US</h4>
        <h4>ABOUT US</h4>
        <h4>ABOUT US</h4>
        <h4>ABOUT US</h4>
        <h4>ABOUT US</h4>
        <h4>ABOUT US</h4>
        <h4>ABOUT US</h4>
        <h4>ABOUT US</h4>
        <h4>ABOUT US</h4>
        <h4>ABOUT US</h4>
        <h4>ABOUT US</h4>
      </Marquee>
      <div className={c.container}>
        <div className={c.content}>
          <h3>О НАС</h3>
          <p>
            В Гинекология Ташкент работают опытные. высококвалифицированные
            врачи, которые постоянно совершенствуют свои знания, проходят
            специальное обучение ка ждой методике, применяемой в центре. что
            подтвер кдается сертификатами
          </p>
          <button>
            подробнее о клинике <FaArrowRightLong />
          </button>
        </div>
        <img src={pic} alt="adminstration" className={c.picture} />
      </div>
    </>
  );
};

export default AboutM;
