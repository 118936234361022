import React from "react";
import c from "./Footer.module.scss";
import logo from "../../assets/logo.png";
import { FaInstagram, FaTelegram } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className={c.social}>
        <img src={logo} alt="logo" />
        <div className={c.social_links}>
          <a
            href="https://www.instagram.com/ginekologiyatashkent?igsh=eHRkaTcxa3V3bnFnm"
            target="blank"
          >
            <FaInstagram />
          </a>
          <a href="https://t.me/ginekologiyatashkent" target="blank">
            <FaTelegram />
          </a>
        </div>
      </div>
      <div className={c.info}>
        <h3>Услуги</h3>
        <ul>
          <li>
            <Link to={"/service"}>Диагностические процедуры</Link>
          </li>
          <li>
            <Link to={"/service"}>
              Косметологические и эстетические процедуры
            </Link>
          </li>
          <li>
            <Link to={"/service"}>Лечение заболеваний</Link>
          </li>
          <li>
            <Link to={"/service"}>Консультации гинеколога</Link>
          </li>
        </ul>
      </div>
      <div className={c.info}>
        <h3>Клиника</h3>
        <ul>
          <li>
            <Link to={"/about"}>О нас</Link>
          </li>
          <li>
            <a href="tel: +998 90 898 55 78">Вакансии</a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
