import React from "react";
import c from "./Partner.module.scss";
import partner1 from "../../assets/partner1.png";
import partner2 from "../../assets/partner2.png";
import partner3 from "../../assets/partner3.png";

const Partner = () => {
  return (
    <div className={c.partner_container}>
      <h3>НАШИ ПАРТНЕРЫ</h3>
      <div className={c.partners}>
        <img src={partner1} alt="parner" />
        <img src={partner2} alt="parner" />
        <img src={partner3} alt="parner" />
      </div>
    </div>
  );
};

export default Partner;
