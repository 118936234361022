import React, { useState } from "react";
import c from "./ServiceBanner.module.scss";
import banner from "../../assets/serviceBanner.png";
import Popup from "../popUp/PopUp";

const ServiceBanner = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  return (
    <>
      <div className={c.banner_container}>
        <div className={c.content}>
          <h2>Гинекология Ташкент</h2>
          <p>
            В Гинекология Ташкент представлен широкий спектр косметологических
            аппаратных, инъекционных и мануальных процедур, направленных на то,
            чтобы самым качественным образом улучшить состояние вашей кожи, лица
            и тела. Мы собрали команду настоящих профессионалов в сфере
            эстетической медицины, чтобы вы могли гарантированно получить лучший
            результат и лучший beauty-сервис.
          </p>
          <button onClick={openPopup} target="blank">
            ЗАПИСАТЬСЯ
          </button>
        </div>
        <img src={banner} alt="service banner" />
      </div>
      {isPopupOpen && <Popup onClose={closePopup} />}
    </>
  );
};

export default ServiceBanner;
