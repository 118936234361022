import React from "react";
import c from "./Banner.module.scss";
import banner1 from "../../assets/banner1.png";
import banner2 from "../../assets/banner2.png";
import banner3 from "../../assets/banner3.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div class={c.mu_banner}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        effect={"fade"}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        modules={[EffectFade, Autoplay]}
        className={c.mySwiper}
      >
        <SwiperSlide className={c.swiper_slide}>
          <img src={banner1} alt="banner" />
        </SwiperSlide>
        <SwiperSlide className={c.swiper_slide}>
          <img src={banner2} alt="banner" />
        </SwiperSlide>
        <SwiperSlide className={c.swiper_slide}>
          <img src={banner3} alt="banner" />
        </SwiperSlide>
        <div class={c.mu_banner__content}>
          <h1>Гинекология Ташкент</h1>
          <p>центр эстетической косметологии</p>
          <Link to={"/service"}>выбрать услугу</Link>
        </div>
      </Swiper>
    </div>
  );
};

export default Banner;
