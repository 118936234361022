import React from "react";
import c from "./Apparats.module.scss";
import Marquee from "react-fast-marquee";
import image1 from "../../assets/apparatus1.png";
import image2 from "../../assets/apparatus2.png";
import image3 from "../../assets/apparatus3.png";
import image4 from "../../assets/apparatus4.png";

const data = [
  {
    title: "Smas лифтинг",
    image: image1,
  },
  {
    title: "ENDOSPHERE",
    image: image2,
  },
  {
    title: "laser",
    image: image3,
  },
  {
    title: "Hydro facial",
    image: image4,
  },
];

const Apparats = () => {
  return (
    <div className={c.all_apparatus}>
      <div className={c.marquee_container}>
        <Marquee gradient={false} speed={40} className={c.marquee}>
          <h4>APPARATUS</h4>
          <h4>APPARATUS</h4>
          <h4>APPARATUS</h4>
          <h4>APPARATUS</h4>
          <h4>APPARATUS</h4>
          <h4>APPARATUS</h4>
          <h4>APPARATUS</h4>
          <h4>APPARATUS</h4>
          <h4>APPARATUS</h4>
          <h4>APPARATUS</h4>
          <h4>APPARATUS</h4>
          <h4>APPARATUS</h4>
          <h4>APPARATUS</h4>
          <h4>APPARATUS</h4>
          <h4>APPARATUS</h4>
          <h4>APPARATUS</h4>
        </Marquee>
        <h3>ОБОРУДОВАНИЕ</h3>
      </div>
      <div className={c.apparatus}>
        {data.map((item, index) => (
          <div key={index} className={c.apparatus_item}>
            <img src={item.image} alt={item.title} />
            <h4>{item.title.toUpperCase()}</h4>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Apparats;
