import React from "react";
import c from "./Location.module.scss";
import location from "../../assets/location.png";

const Location = () => {
  return (
    <div className={c.location_container}>
      <img src={location} alt="location" />
      <div className={c.location_info}>
        <div className={c.contact}>
          <h3>Связаться с нами:</h3>
          <a href="tel: +998 (90) 667 55 44">+998 (90) 667 55 44</a>
          <a href="tel: +998 (90) 667 55 44">+998 (90) 667 55 44</a>
        </div>
        <div className={c.location}>
          <h3>Наш адрес:</h3>
          <p>
            Узбекистан, 100093, Ташкент, Юнусабадский район, кв-л Юнусабад-4,
            84.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Location;
